import React from "react"
import PricingNavbar from "../Pricing/PricingNavbar/PricingNavbar"
import "../../styles/integrations/Integrations.css"
import mailchimp from "../../../static/assets/mailchimp.png"
import yotpo from "../../../static/assets/yotpa.png"
import wisepops from "../../../static/assets/wisepops.png"
import klaviyo from "../../../static/assets/klaviyo.png"
import typeform from "../../../static/assets/typeform.png"
import zapier from "../../../static/assets/zapier.png"
import gorgias_logo from "../../../static/assets/gorgias_logo.png"
import InfluencerFooter from "../LandingSubComponents/InfluencerFooter/InfluencerFooter"
import {useParams} from 'react-router-dom'
function Integrations() {
  
  const data = [
    {
      header: "klaviyo",
      unique_name: "klaviyo",
      info: "Automate Influencer flows using our Klaviyo Integration. Incentivise customers to post based on how Influencial they are and auto send rewards on mention using Klaviyo flows.",
      btnText: "learn more",
      imgSrc: `${klaviyo}`,
    },

    {
      header: "yotpo loyalty",
      unique_name: "yotpo",
      info: "Reward your customers for Instagram Mentions as part of your broader loyalty and rewards program in Yotpo.\n" +
        "\n" +
        "For example: Earn 500 points for mentioning us on Instagram",
      btnText: "learn more",
      imgSrc: `${yotpo}`,
    },
    {
      header: "Gorgias",
      unique_name: "gorgias",
      info: "Influencerbit adds Instagram insights to Support processes. Get social influence of the customer with Influencerbit widget at Gorgias & create ticket to thank them personally whenever anyone mentions you on Instagram",
      btnText: "learn more",
      imgSrc: `${gorgias_logo}`,
    },
    {
      header: "wisepops",
      unique_name: "wisepops",
      info: "Mailchimp is an American marketing automation platform and email marketing service, used by businesses to manage their mailing lists and create email marketing campaigns and automations to send to customers.",
      btnText: "learn more",
      imgSrc: `${wisepops}`,
    },

    {
      header: "typeform",
      unique_name: "typeform",
      info: "Mailchimp is an American marketing automation platform and email marketing service, used by businesses to manage their mailing lists and create email marketing campaigns and automations to send to customers.",
      btnText: "learn more",
      imgSrc: `${typeform}`,
    },
    {
      header: "zapier",
      unique_name: "zapier",
      info: "Mailchimp is an American marketing automation platform and email marketing service, used by businesses to manage their mailing lists and create email marketing campaigns and automations to send to customers.",
      btnText: "learn more",
      imgSrc: `${zapier}`,
    },
  ]
  return (
    <div className="IntegrationsCol">
      <PricingNavbar />
      <div className="container is-fluid integrationsContainer">
        <div className="columns is-multiline is-mobile">
          <div className="column is-12">
            <h1 className="integrationsHeader has-text-black is-size-2-desktop is-size-4-tablet is-size-4-mobile">
              Our Integrations 
            </h1>
          </div>
        </div>
        <div className="columns integrationsCardContainer is-multiline is-mobile mt-5">
          {data.map((inte, key) => {
            return (
              <div className={`column is-4-desktop is-6-tablet is-12-mobile`}>
                <div className="integrationsCard">
                  <h2 className="has-text-black integrationsCardHeader is-uppercase has-text-centered is-size-6">
                    {inte.header}
                  </h2>
                  <div className="integrationsCardBox mt-6">
                    <img
                    loading="lazy"
                      src={`${inte.imgSrc}`}
                      alt="integrationsCardImg"
                      className="image integrationsBrandImg"
                      title="integrationsCardImg"
                    />
                  </div>
                  <p className="has-text-black integrationsCardInfo mt-6">
                    {inte.info}
                  </p>
                  <div className="">
                    <a
                      href={`/integrations/${inte.unique_name}`}
                      className="button is-uppercase is-medium is-fullwidth has-text-white integrationsLearnBtn mt-5 is-size-6"
           
                    >
                  {inte.btnText}
                    </a>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="mt-5">
          <InfluencerFooter/>
      </div>
    </div>
  )
}

export default Integrations
