import React from 'react'
import Integrations from '../../components/Integrations/Integrations'
import PageSeo from '../../components/SEO'

function index() {
    return (
        <>
          <PageSeo pageTitle="Integrations"/>
          <Integrations/>
        </>
    )
}

export default index
